<template>
  <BaseCard
    heading="Active Webengines"
    icon="mdi-laptop-mac"
    :height="$vuetify.breakpoint.smAndUp ? '514' : ''"
  >
    <DataTableExtended
      ref="table"
      :calculate-widths="true"
      :headers="headers"
      :items="webengines"
      :options.sync="options"
      :footerOptions="footerOptions"
      class="elevation-0 border"
      :loading="loading"
      :no-data-text="loading ? 'Loading…' : 'No data available'"
      :items-per-page.sync="options.itemsPerPage"
      :server-items-length="webenginesCount"
      @update:options="fetchTableData(options)"
      :mobile-breakpoint="0"
      :withUrlParams="false"
      data-testid="table-active-webengines"
    >
      <template v-slot:top>
        <v-row class="pa-3">
          <v-col cols="12" md="12">
            <v-text-field
              outlined
              v-model="search"
              label="Search"
              single-line
              hide-details
              clearable
              data-testid="filter-search-active-webengines"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.play`]="{ item }">
        <v-tooltip top v-if="$vuetify.breakpoint.smAndUp">
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              dark
              x-small
              :color="Pallette.actionButtons.play"
              v-on="on"
              @click="playWebEngine(item)"
              data-testid="btn-open-webengine"
            >
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </template>
          <span>Play Web Engine</span>
        </v-tooltip>
        <v-btn
          v-else
          block
          dark
          small
          :color="Pallette.actionButtons.play"
          @click="playWebEngine(item)"
          data-testid="btn-open-webengine"
        >
          <v-icon>mdi-play</v-icon>
          Play
        </v-btn>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>
    </DataTableExtended>
  </BaseCard>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import DataTableExtended from "@/components/table/DataTableExtended"

export default {
  name: "ActiveWebenginesWidget",

  components: { DataTableExtended },

  mixins: [GeneralMixin],

  data: () => ({
    headers: [
      {
        text: "",
        value: "play",
        sortable: false,
        width: 40,
      },
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: true,
      },
    ],
    webengines: [],
    webenginesCount: 0,
    loading: false,
    options: {
      page: 1,
      itemsPerPage: 5,
    },
    footerOptions: {
      "items-per-page-text": "",
      "items-per-page-options": [5],
    },
    serverPagination: {
      p: 1,
      pp: 5,
      sort: "",
      order: "",
    },
    search: "",
  }),

  mounted: function () {
    if (this.isAuthenticated) this.fetchTableData(this.$refs?.table?.options)
  },

  watch: {
    search() {
      this.fetchTableData(this.$refs?.table?.options)
    },
  },

  methods: {
    fetchTableData(options) {
      let self = this
      if (options) {
        self.serverPagination.sort =
          options.sortBy && options.sortBy[0] ? options.sortBy[0] : "name"
        self.serverPagination.order =
          options.sortDesc && options.sortDesc[0] ? "DESC" : "ASC"
      }

      let params = {
        p: self.options.page,
        pp: self.options.itemsPerPage,
        // keep: '' // @todo define what fields acualy need for webengine interface
      }
      if (self.search !== "") params.search = self.search
      if (self.serverPagination.sort !== "")
        params.sort = self.serverPagination.sort
      if (self.serverPagination.order !== "")
        params.order = self.serverPagination.order
      self.loading = true
      self.$store
        .dispatch("webenginesGetList", { params })
        .then(function (response) {
          self.webengines = response?.data?.data?.web_engines?.result || []
          self.webenginesCount =
            response?.data?.data?.web_engines?.full_count || 0
          self.loading = false
        })
    },
    playWebEngine(item) {
      this.$router
        .push({
          path: `/engines/webengine/streaming/${item._key}`,
        })
        .catch((err) => {})
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      Pallette: "get_Pallette",
    }),
  },
}
</script>
